<template>
  <BarChartStyled @mouseout="clearTooltip()">
    <VChart
      :option="option"
      :theme="selectedTheme"
      :updateOptions="{ notMerge: true }"
      autoresize
      @mouseover="addTooltip($event)"
      @mouseout="removeTooltip()"
      ref="simpleBarChart"
    />
  </BarChartStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import VChart from 'vue-echarts'
import { LegendComponent } from 'echarts/components'
import { GraphicComponent, GridComponent, TooltipComponent } from 'echarts/components'
import { DarkEChartTheme, LightEChartTheme } from '@styles/themes'
import chartAxisLabelMixin from '@/mixins/chartAxisLabel'

use([CanvasRenderer, BarChart, GraphicComponent, GridComponent, TooltipComponent, LegendComponent])

const BarChartStyled = styled('div')`
  display: flex;
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export default {
  inject: ['theme'],
  mixins: [chartAxisLabelMixin],
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  components: {
    BarChartStyled,
    VChart,
  },

  computed: {
    selectedTheme() {
      return this.theme.isDark ? DarkEChartTheme : LightEChartTheme
    },
  },
  methods: {
    addTooltip(event) {
      const chartRef = this.$refs.simpleBarChart
      this.appendTooltip(chartRef, event)
    },
    removeTooltip() {
      this.clearTooltip()
    },
  },
}
</script>
