<template>
  <HistogramChartStyled :header="header">
    <WidgetHeaderMolecule :title="$t('widgetTypes.HISTOGRAM')" :assetDimensions="assetDimensionDetails" v-if="header" />
    <GraphStyled>
      <NoDataBlockStyled v-if="!hasData">
        {{ message }}
      </NoDataBlockStyled>
      <BarChart :option="option" v-else />
    </GraphStyled>
  </HistogramChartStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import BarChart from '../Atoms/SimpleBarChart'
import WidgetHeaderMolecule from '@/components/Atomic/Molecules/WidgetHeaderMolecule'
import { flexCenter } from '@styles/mixins'

const HistogramChartStyled = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  ${({ header }) =>
    header
      ? header &&
        `
      grid-template-areas:
        "widgetHeader"
        "graph";
      grid-template-rows: 5rem 1fr`
      : `grid-template-areas: "graph";
      grid-template-rows: 100%;`};
  grid-template-columns: 1fr;
`
const GraphStyled = styled('div')`
  grid-area: graph;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.25s ease;
`
const NoDataBlockStyled = styled('div')`
  ${flexCenter}
  height: 100%;
  width: 100%;
`

export default {
  inject: ['theme'],
  props: {
    option: {
      required: true,
      type: Object,
    },
    assetDimensionDetails: {
      required: false,
      type: Array,
    },
    widgetHeader: {
      required: false,
      type: Boolean,
      default: false,
    },
    hasData: {
      required: true,
      type: Boolean,
    },
    count: {
      required: true,
      type: Number,
    },
  },
  components: {
    BarChart,
    HistogramChartStyled,
    GraphStyled,
    NoDataBlockStyled,
    WidgetHeaderMolecule,
  },
  computed: {
    header() {
      return this.widgetHeader
    },
    message() {
      return this.$t('messages.noDimensionData', { dimension: this.assetDimensionDetails[0]?.title })
    },
  },
}
</script>
