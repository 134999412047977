<template>
  <TalpaLoaderWrapper v-if="$apollo.loading || histogramData === null" />
  <HistogramWidget
    v-else
    :widgetHeader="true"
    :assetDimensionDetails="assetDimensionDetails"
    :option="option"
    :count="assets.length"
    :hasData="histogramData.length > 0"
  />
</template>

<script>
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import localesMixin from '@/mixins/locales'
import { TalpaLoaderWrapper } from '@common/components'

import { DarkEChartTheme, LightEChartTheme } from '@styles/themes'

import HISTOGRAM_DATA from '#/graphql/assetDimensions/histogramData.gql'
import HistogramWidget from '@/components/Atomic/Molecules/HistogramWidget'
import assetDimensionWithDurationFormat from '@/utils/widgets/assetDimensionWithDurationFormat'
import fullTimeDurationFormatter from '@/utils/duration'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

export default {
  mixins: [localesMixin],
  inject: ['theme', 'uiSettings'],
  props: {
    assetDimension: {
      type: Object,
      required: true,
    },
    widgetSettings: {
      type: Object,
      required: true,
    },
    assets: {
      type: Array,
      required: true,
    },
    selectedTimeframeParam: {
      type: Object,
      required: true,
    },
  },
  components: {
    HistogramWidget,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      histogramData: null,
    }
  },
  computed: {
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    colors() {
      return this.theme.isDark ? DarkEChartTheme.color : LightEChartTheme.color
    },
    assetDimensionName() {
      return get(this.assetDimension, 'name', null)
    },
    assetIds() {
      return this.assets.map(({ id }) => id)
    },
    unitSI() {
      const unitSI = get(this.assetDimension, 'physicalUnitSI', null)
      return unitSI
    },
    unitUI() {
      const unitUIMetric = get(this.assetDimension, 'physicalUnitUIMetric', '')
      const unitUIImperial = get(this.assetDimension, 'physicalUnitUIImperial', null)
      const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
      return unitUI
    },
    isDurationFormatAvailable() {
      return assetDimensionWithDurationFormat.includes(this.assetDimensionName)
    },
    hasDecimalFormatEnabled() {
      return this.widgetSettings?.hasDecimalFormatEnabled
    },
    isTimeBasedKPI() {
      return this.unitSI === 's' && !this.hasDecimalFormatEnabled && this.isDurationFormatAvailable
    },
    hasCountFormatEnabled() {
      return this.widgetSettings?.hasCountFormatEnabled
    },
    assetDimensionDetails() {
      return [
        {
          id: this.assetDimensionName,
          title: this.assetDimension?.nameTranslations
            ? getAssetDimensionNameByLocale(this.assetDimension?.nameTranslations, this.locale)
            : this.assetDimensionName,
          value:
            this.assetDimensionData !== null
              ? this.isTimeBasedKPI
                ? fullTimeDurationFormatter(this.assetDimensionData)
                : this.convUnit(this.assetDimensionData, this.unitSI, this.unitUI, 2, false, false)
              : '-',
          unit: this.unitUI ? this.unitUI.replace('mt', 't') : this.unitSI,
          color: this.colors[0],
        },
      ]
    },
    customBinMapped() {
      const customBin = { ...this.widgetSettings }
      if (customBin) {
        delete customBin['id']
        delete customBin['__typename']
      }
      const thresholdMin = get(customBin, 'thresholdMin', null) === '' ? null : get(customBin, 'thresholdMin', null)
      const thresholdMax = get(customBin, 'thresholdMax', null) === '' ? null : get(customBin, 'thresholdMax', null)
      const sizeOfBin = get(customBin, 'sizeOfBin', null) === '' ? null : get(customBin, 'sizeOfBin', null)
      return {
        thresholdMin: thresholdMin !== null ? this.convUnit(thresholdMin, this.unitSI, this.unitUI, 1, false, false, true) : thresholdMin,
        thresholdMax: thresholdMax !== null ? this.convUnit(thresholdMax, this.unitSI, this.unitUI, 1, false, false, true) : thresholdMax,
        sizeOfBin: sizeOfBin !== null ? this.convUnit(sizeOfBin, this.unitSI, this.unitUI, 1, false, false, true) : sizeOfBin,
        noOfBin: get(customBin, 'noOfBin', null),
      }
    },
    histogramDataMapped() {
      const data = this.histogramData.map(hist => {
        const left = hist.left
          ? this.isTimeBasedKPI
            ? fullTimeDurationFormatter(hist.left)
            : this.convUnit(hist.left, this.unitSI, this.unitUI, 2, true, true)
          : null
        const right = hist.right
          ? this.isTimeBasedKPI
            ? fullTimeDurationFormatter(hist.right)
            : this.convUnit(hist.right, this.unitSI, this.unitUI, 2, true, true)
          : null
        let binName = ''
        if (left === null) {
          binName = `<${right}`
        } else if (right === null) {
          binName = `≥${left}`
        } else {
          binName = `${left} - ${right}`
        }
        const percentage = this.convUnit(hist.percentage, '%', '%', 2, false, false)
        const count = this.convUnit(hist.count, '', '', 2, false, false, true)
        const value = this.hasCountFormatEnabled ? hist.count : percentage
        return {
          ...hist,
          percentage,
          count,
          value,
          binName,
          left,
          right,
          unit: this.unitUI,
          name: this.assetDimensionName,
        }
      })
      return data
    },
    histogramDataSorted() {
      return orderBy(this.histogramDataMapped, 'binOrder', 'asc')
    },
    histogramDatasets() {
      let dataSet = []
      dataSet = this.histogramDataSorted.map(item => {
        const percentage = item.percentage
        const count = item.count
        return {
          value: item.value,
          percentage,
          count,
        }
      })
      return dataSet
    },
    dimensionY() {
      const axisUnit = !this.hasCountFormatEnabled ? '%' : this.$t('count')
      return axisUnit
    },
    histogramLabels() {
      let labels = []
      labels = this.histogramDataMapped.map(item => item.binName)
      return labels
    },
    option() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: function (params) {
            return `${params[0].data.percentage}% <br> ${params[0].data.count} <br>${params[0].axisValueLabel} `
          },
        },
        grid: {
          left: '15',
          right: '15',
          bottom: '15',
          top: '27',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.histogramLabels,
            axisLabel: {
              color: this.theme.colors.textActivePrimary,
            },
            axisTick: {
              show: true,
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: this.dimensionY,
            nameTextStyle: {
              color: this.theme.colors.textActivePrimary,
            },
            axisLabel: {
              color: this.theme.colors.textActivePrimary,
              formatter: params => {
                return this.numberLocalized(params)
              },
            },
            splitLine: {
              lineStyle: {
                color: this.theme.colors.atomic.mildgrey,
              },
            },
          },
        ],
        series: [
          {
            name: this.assetDimension?.nameTranslations
              ? getAssetDimensionNameByLocale(this.assetDimension?.nameTranslations, this.locale)
              : this.assetDimensionName,
            data: this.histogramDatasets,
            type: 'bar',
          },
        ],
      }
    },
  },

  apollo: {
    assetDimensionData: {
      query: require('@/graphql/assetDimension/assetDimensionTrend.gql'),
      variables() {
        return {
          where: {
            assetDimension: {
              name: this.assetDimensionName,
            },
            timeframe: this.selectedTimeframeParam,
            assets: {
              id_in: this.assetIds,
            },
          },
        }
      },
      skip() {
        return !this.selectedTimeframeParam || !this.assetDimensionName || this.assets.length < 1
      },
      update({ assetDimensionData }) {
        const total = assetDimensionData?.total ?? null
        return total
      },
    },
    histogramData: {
      query: HISTOGRAM_DATA,
      variables() {
        return {
          where: {
            dimension: this.assetDimensionName,
            assets: {
              id_in: this.assetIds,
            },
            customBin: this.customBinMapped,
            start: this.selectedTimeframeParam.start,
            end: this.selectedTimeframeParam.end,
            timezone: this.selectedTimeframeParam.timezone,
          },
        }
      },
      skip() {
        return this.assetIds.length < 1 || !this.assetDimensionName
      },
      update(data) {
        return get(data, 'histogramData', [])
      },
    },
  },
}
</script>
