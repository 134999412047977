var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BarChartStyled', {
    on: {
      "mouseout": function mouseout($event) {
        return _vm.clearTooltip();
      }
    }
  }, [_c('VChart', {
    ref: "simpleBarChart",
    attrs: {
      "option": _vm.option,
      "theme": _vm.selectedTheme,
      "updateOptions": {
        notMerge: true
      },
      "autoresize": ""
    },
    on: {
      "mouseover": function mouseover($event) {
        return _vm.addTooltip($event);
      },
      "mouseout": function mouseout($event) {
        return _vm.removeTooltip();
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }