const axisLabelsMixin = {
  data() {
    return {
      axisTooltipStyle: '',
    }
  },
  methods: {
    appendTooltip(chartRef, event, customToolTipText) {
      const chart = chartRef
      const axisTooltipDOM = document.createElement('div')
      const axisTooltipContent = document.createElement('div')
      const axisTooltipArrow = document.createElement('div')
      axisTooltipArrow.style.cssText =
        'position: absolute; width: 6px; height: 6px; left: 30%; bottom: -4px; transform: translateX(-50%) rotate(45deg); border-bottom: #ddd solid 1px; border-right: #ddd solid 1px; background-color: #fff;'
      axisTooltipDOM.appendChild(axisTooltipContent)
      axisTooltipDOM.appendChild(axisTooltipArrow)
      axisTooltipDOM.style.cssText =
        'position: absolute; visibility: hidden; max-width: 50%;background-color: #fff; color:#333 ; font-size: 12px; padding: 5px 15px; border-radius: 2px; box-shadow: 0 0 2px #aaa; transition: transform ease .3s, visibility ease .3s; transform: scale(0); transform-origin: bottom;'
      this.axisTooltipStyle = axisTooltipDOM.style
      // append to ECharts container

      chart.getDom().appendChild(axisTooltipDOM)

      if (event.targetType !== 'axisLabel') {
        return
      }
      if (event.componentType !== 'yAxis') {
        return
      }
      const currLabel = event.event.target
      const displayText = currLabel.style.text
      const fullText = customToolTipText ? customToolTipText : event.value
      if (fullText === displayText) {
        // not overflow
        return
      }
      axisTooltipContent.innerText = customToolTipText ? customToolTipText : fullText
      // overflow, show full text
      this.axisTooltipStyle.left = currLabel.transform[4] - axisTooltipDOM.offsetWidth / 3 + 'px'
      this.axisTooltipStyle.top = currLabel.transform[5] - axisTooltipDOM.offsetHeight - 15 + 'px'
      this.axisTooltipStyle.transform = ''
      this.axisTooltipStyle.visibility = 'visible'
      return this.axisTooltipStyle
    },
    clearTooltip() {
      if (this.axisTooltipStyle !== '') {
        this.axisTooltipStyle.visibility = 'hidden'
        this.axisTooltipStyle.transform = 'scale(0)'
        return this.axisTooltipStyle
      }
    },
  },
}

export default axisLabelsMixin
