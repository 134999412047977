var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HistogramChartStyled', {
    attrs: {
      "header": _vm.header
    }
  }, [_vm.header ? _c('WidgetHeaderMolecule', {
    attrs: {
      "title": _vm.$t('widgetTypes.HISTOGRAM'),
      "assetDimensions": _vm.assetDimensionDetails
    }
  }) : _vm._e(), _c('GraphStyled', [!_vm.hasData ? _c('NoDataBlockStyled', [_vm._v(" " + _vm._s(_vm.message) + " ")]) : _c('BarChart', {
    attrs: {
      "option": _vm.option
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }