<template>
  <AssetDimensionHistogramWidget
    v-if="assetDimension && selectedTimeframeParam && assetsSelected && widgetSettings"
    :assetDimension="assetDimension"
    :widgetSettings="widgetSettings"
    :selectedTimeframeParam="selectedTimeframeParam"
    :assets="assetsSelected"
  />
</template>

<script>
import get from 'lodash/get'
import { TimeframeMixin } from '@common/mixins'
import resizeMixin from '@/mixins/resize'
import AssetDimensionHistogramWidget from '@/components/Atomic/Organisms/Dashboard/AssetDimensionHistogramWidget'
import { useAssetStore } from '@/stores/assets'

export default {
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  inject: ['theme'],
  mixins: [TimeframeMixin, resizeMixin],
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  components: {
    AssetDimensionHistogramWidget,
  },
  computed: {
    dimensions() {
      const dimensions = get(this.widget, 'dimensions', [])
      return dimensions
    },
    widgetSettings() {
      return get(this.widget, 'widgetSettings', [])
    },
    assetDimension() {
      return get(this.dimensions[0], 'assetDimension', null)
    },
    assetsSelected() {
      return this.assetStore.assetsSelected()
    },
  },
}
</script>
