var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.assetDimension && _vm.selectedTimeframeParam && _vm.assetsSelected && _vm.widgetSettings ? _c('AssetDimensionHistogramWidget', {
    attrs: {
      "assetDimension": _vm.assetDimension,
      "widgetSettings": _vm.widgetSettings,
      "selectedTimeframeParam": _vm.selectedTimeframeParam,
      "assets": _vm.assetsSelected
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }