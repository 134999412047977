var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading || _vm.histogramData === null ? _c('TalpaLoaderWrapper') : _c('HistogramWidget', {
    attrs: {
      "widgetHeader": true,
      "assetDimensionDetails": _vm.assetDimensionDetails,
      "option": _vm.option,
      "count": _vm.assets.length,
      "hasData": _vm.histogramData.length > 0
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }